import { useState, useRef } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink, useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import axios from "axios";
import {apiConfig}from "../../../services/apiConfig";
import useAuth from "../../../auth/useAuth";
import "./styles/ItemImport.css";
import Spinner from 'react-bootstrap/Spinner';
const CustomerImport = () => {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const inputRef = useRef("");
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [contacts, setContacts] = useState([]);
  const [habilitarBoton, setHabilitarBoton] = useState(false); 
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csv = e.target.result;
      const lines = csv.split('\n');
      const headers = lines[0].split(',');

      const nameIndex = headers.indexOf('First Name');
      const givenNameIndex = headers.indexOf('Last Name');
      const additionalNameIndex = headers.indexOf('Additional Name');
      const birthdayIndex = headers.indexOf('Birthday');
      const emailIndex = headers.indexOf('E-mail 1 - Value');
      const phoneIndex = headers.indexOf('Phone 1 - Value');
      

      const parsedContacts = [];
      for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(',');

        if (values.length === headers.length) {
          const phone = values[phoneIndex].replace(/\D/g, ''); // Elimina los caracteres no numéricos
          const lastTenDigits = phone.slice(-10); // Obtiene los últimos 10 dígitos

          const contact = {
            seudonimo: values[nameIndex],
            nombre: values[nameIndex],
            apellido: values[givenNameIndex],
            email: values[emailIndex],
            fecha_nac: values[birthdayIndex],
            avatar_url: 'no-avatar.png',
            telefono: lastTenDigits,
            id_business_office: user.id_business_office,
            id_business_unit: user.id_business_unit,            
          };

          parsedContacts.push(contact);
        }
      }

      setContacts(parsedContacts);
    };

    reader.readAsText(file);
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);
  };
  const onChange = () => {
    triggerFileSelectPopup();
  };

  let history = useHistory();
  const [actBtn, setActBtn] = useState(true); 

  const onSelectData = () => {
    setActBtn(false)
    const formattedData = { contacts: contacts };
    axios
      .post(apiConfig + `customer/import/upload.php`,  formattedData)
      .then((res) => {
        if (res.data.success === 1) {
          history.push("/config/customer/");
        } else {
          alert(res.data.message);
        }
      })
      .catch(function (res) {});
  };




  return (
    <div className="container" >
        <div className="text-start">
            <NavLink exact to={"/config/customer"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
          <div className="card shadow mb-3" style={{ background: "#736CED" }}>
            <div className="card-body">
              <div className="text-center text-white">Importar Clientes</div>
            </div>
          </div>    

      
      <div className="container text-start pt-4">
        Puedes importar la lista de contactos de Google en <a className="text-primary" href="https://contacts.google.com/" target="_blank" rel="noopener noreferrer">https://contacts.google.com/</a>.<br/>
        1. Exporta el archivo de Google en formato CSV.<br/>
        2. Sube el archivo. Cronogram solo cargará los contactos que no se encuentran registrados.<br/>
        3. Confirma la lista de contactos subidos.
      </div>

      <div>
        <div className="text-end text-muted mb-3"></div>
        {!contacts.length > 0 && (
          <form >
            <div className="mb-5 text-center">
              <input
                type="file"
                name="file"
                accept=".csv"
                ref={inputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
                required
              />
              <BsIcons.BsFileEarmarkArrowUp
                className="display-1"
                onClick={onChange}
              />
            </div>
          </form>
        )}
            {contacts.length > 0 && (
              <div className="aling">
                <div>
                  Total de Registros: <strong>{contacts.length}</strong>{" "}
                </div>
                <button
                  className="btn app-button  shadow"
                  onClick={actBtn && onSelectData}
                >
                  Confirmar
                </button>
              </div>
            )}
      {contacts.length > 0 && (
        <Table responsive="sm">
          <thead>
            <tr className="text-start">
              <th>Nombre</th>
              <th>Apellido</th>              
              <th>Teléfono</th>                        
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact, index) => (
              <tr className="text-start" key={index}>
                <td>{contact.nombre}</td>
                <td>{contact.apellido}</td>
                <td>{contact.telefono}</td>                
                <td>
                <BsIcons.BsTrash  onClick={() => handleDeleteContact(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}         
          
     
      </div>
    </div>
  );
};

export default CustomerImport;
