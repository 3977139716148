import React, { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { Table, Container, Button } from "react-bootstrap";
import axios from "axios";
import { apiConfig } from "../../services/apiConfig";
import moment from "moment";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useData } from "../../DataContext/DataContext";

const Report = () => {
  const {  locales, integraciones } = useData();

  const day = moment().format("YYYY-MM-DD");
  const [turnos, setTurnos] = useState(null);

  const [filtro, setFiltro] = useState("");
  const [desde, setDesde] = useState(day);
  const [hasta, setHasta] = useState(day);
  const [local, setLocal] = useState("");
  const [estadoPago, setEstadoPago] = useState(3);
  const [buttonActive, setButtonActive] = useState(false);
  const [buttonText, setButtonText] = useState("Enviar enlace de pago");
  const [hideButton, setHideButton] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    instances: "",
    tokenWs: "",
     id_business_unit: user.id_business_unit,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiConfig + "wapi/getWapi.php?id_business_unit="+user.id_business_unit);
        if (response.data) {
          setFormData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user.id_business_unit]);

  const buscarTurnos = () => {
    if (desde && hasta && local) {
      setTurnos(null);
      axios
        .get(apiConfig + "pay/list/list.php", {
          params: {
            id: local,
            desde: desde,
            hasta: hasta,
            id_estado_pago: estadoPago || "",
          },
        })
        .then((response) => {
          let res = response.data.data;

          if (res.length > 0) {
            setTurnos(res);
          } else {
            window.alert("No se encontraron registros");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.alert("Debes completar los campos");
    }
  };

  const colorMap = {
    1: "#fa5c7c", // "solicitado"
    2: "#0acf97", // "reservado"
  };


  async function notificar(a) {
    if (a.length > 0) {
      setButtonActive(true);
      setButtonText("");

      setTimeout(() => {
        setHideButton(true);
        // document.body.style.backgroundColor = '#00a650';
      }, 8000);

      for (const registro of a) {
        const phoneNumber = registro.telefono;

        const message = !enlace
          ? `Hola, como estas? Quiero recordarte tu turno de ${
              registro.tratamiento
            } del dia ${moment(registro.fecha).format(
              "dddd D/MM/YYYY"
            )} a las ${registro.hora}.  Gracias!`
          : `Hola, como estas? Quiero recordarte tu turno de ${
              registro.tratamiento
            } del dia ${moment(registro.fecha).format(
              "dddd D/MM/YYYY"
            )} a las ${
              registro.hora
            }. Se encuentra pendiente de pago, puedes abonar a traves del siguiente https://bioma.cronogram.com.ar/back/pay/?id=${
              registro.codigo
            }. Gracias!`;

        try {
          const options = {
            method: "POST",
            url:
              "https://waapi.app/api/v1/instances/" +
              formData.instances +
              "/client/action/send-message",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
              Authorization: "Bearer " + formData.tokenWs,
            },
            data: {
              chatId: "549" + phoneNumber + "@c.us",
              message: message,
            },
          };

          await axios
            .request(options)
            .then(function (response) {
              console.log(response.data);
            })
            .catch(function (error) {
              console.error(error);
            });
        } catch (error) {
          // console.error("Error al enviar el mensaje de WhatsApp:", error);
        }
      }
    }
  }

  useEffect(() => {
    setButtonActive(false);
    setButtonText("Enviar whatsapp");
    setHideButton(false);
  }, [filtro]);



  const [datos, setDatos] = useState([]);
  const [btnAll, setBtnAll] = useState(false);

  const handleClickSelect = (e) => {
    setDatos([...datos, e]);
    setBtnAll(false);
  };

  const handleClickAll = () => {
    // Asumiendo que "turnos" es un array definido en otro lugar del código
    setDatos(turnos);
    setBtnAll(true);
  };

  const handleClickNot = () => {
    setDatos([]);
    setBtnAll(false);
  };
  const handleClickNotd = (a) => {
    const lista = datos.filter((x) => Number(x.id) !== Number(a.id));
    setDatos(lista);
  };
  const handleClickNuevo = () => {
    setHideButton(false);
    setButtonActive(false);
    setButtonText("Enviar whatsapp");
    setDatos([]);
  };
  const [enlace, setEnlace] = useState(false);
  const handleClickEnlace = () => {
    setEnlace(!enlace);
  };
  const [enlaceMP, setEnlaceMP] = useState(false);
  const handleClickEnlaceMP = () => {
    setEnlaceMP(!enlace);
  };
  const totalCobrado = (lista) => {
    const total = lista.reduce((acc, curr) => acc + Number(curr.precio), 0);

    const formato = Number(total).toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
    });
    return formato;
  };

  return (
    <Container>
      <div className="text-start">
        <NavLink exact to={"/calendario"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Reporte de Ventas</div>
        </div>
      </div>
      <Row>
        <Col md={6} sm={12}>
          <FloatingLabel
            controlId="floatingActual"
            label="Local"
            className="mb-3"
          >
            <select
              className="form-select"
              name="id_business_office"
              onChange={(e) => setLocal(e.target.value)}
              required
            >
              <option selected></option>
              {locales &&
                locales.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </select>
          </FloatingLabel>
        </Col>
        <Col md={6} sm={12}>
          <FloatingLabel
            controlId="floatingActul"
            label="Estado de Pago"
            className="mb-3"
          >
            <select
              className="form-select"
              name="id_estado_pago"
              onChange={(e) => setEstadoPago(e.target.value)}
              required
            >
              <option>Seleccione una opción</option>
              <option value={""}>Todo</option>
              <option value={1}>Pendiente</option>
              <option value={2}>Pagado</option>
            </select>
          </FloatingLabel>
        </Col>
        <Col md={6} sm={12}>
          <FloatingLabel
            controlId="floatingFecha"
            label="Desde"
            className="mb-3"
          >
            <Form.Control
              type="date"
              name="desde"
              value={desde && desde}
              onChange={(e) => setDesde(e.target.value)}
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={6} sm={12}>
          <FloatingLabel
            controlId="floatingFecha"
            label="Hasta"
            className="mb-3"
          >
            <Form.Control
              type="date"
              name="hasta"
              min={desde}
              value={hasta && hasta}
              onChange={(e) => setHasta(e.target.value)}
              required
            />
          </FloatingLabel>
        </Col>

        <div className="d-grid col-3 gap-2 pt-3 text-end">
          <Button variant="primary" onClick={buscarTurnos}>
            Buscar
          </Button>
        </div>
      </Row>
      <hr />
      <Row>
        <div className="pt-3">
          {locales &&
            locales
              .filter((x) => x.id === local)
              .map((data, index) => (
                <p key={index}>
                  {data.name} del {moment(desde).format("DD/MM/YYYY")} Al{" "}
                  {moment(hasta).format("DD/MM/YYYY")}
                </p>
              ))}
        </div>
        <div className="pt-2 mb-3">
          <div className="input-group ">
            <input
              placeholder="Filtro"
              className="form-control form-control-sm"
              type="text"
              onChange={(e) => setFiltro(e.target.value)}
            />
            <span className="input-group-text" id="basic-addon2">
              <BsIcons.BsSearch />
            </span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col pt-3 mx-auto">
            {enlace ? (
              <BsIcons.BsRecord className="h2" onClick={handleClickEnlace} />
            ) : (
              <BsIcons.BsRecord2Fill
                className="h2"
                onClick={handleClickEnlace}
              />
            )}
            Recordatorio pago
          </div>
          <div className="col pt-3 mx-auto">
          {integraciones.length > 0 && <>
            {enlaceMP ? (
              <BsIcons.BsRecord className="h2" onClick={handleClickEnlaceMP} />
            ) : (
              <BsIcons.BsRecord2Fill
                className="h2"
                onClick={handleClickEnlaceMP}
              />
            )}
             Recordatorio con enlace pago MP
            </>
          }
           
          </div>
          <div className="col text-end">
            {!hideButton ? (
              <button
                id="color-button"
                className={buttonActive ? "active" : ""}
                onClick={() => notificar(datos)}
              >
                {buttonText}
              </button>
            ) : (
              <button id="color-button-exito" onClick={handleClickNuevo}>
                Mensaje enviado
              </button>
            )}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col text-start">
            {" "}
            Total registros: {turnos && turnos.length}
          </div>
          <div className="col text-end">
            Desde: {moment(desde).format("DD-MM-YYYY")} - Hasta:{" "}
            {moment(hasta).format("DD-MM-YYYY")}
          </div>
        </div>
        <Table striped bordered hover className="mb-5">
          <thead>
            <tr>
              <th>#</th>
              <th>Código</th>
              <th>Nombre</th>
              <th>Concepto</th>
              <th>Monto</th>
              <th>Estado</th>
              <th>
                <h4>
                  {!btnAll ? (
                    <BsIcons.BsRecord onClick={handleClickAll} />
                  ) : (
                    <BsIcons.BsRecord2Fill onClick={handleClickNot} />
                  )}
                </h4>
              </th>
            </tr>
          </thead>
          <tbody>
            {turnos &&
              turnos
                .filter(
                  (datos) =>
                    datos.codigo
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.tratamiento
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.nombre
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.apellido
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.estado_pago
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.fecha
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase())
                )
                .map((a, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>#{a.codigo}</td>
                    <td>{a.nombre + " ," + a.apellido}</td>
                    <td>
                      <small>{a.tratamiento}</small>
                      <br />
                      <small>
                        {moment(a.fecha).format("DD-MM-YYYY")} {a.hora}
                      </small>
                    </td>
                    <td className="text-end">
                      <small>
                        {Number(a.precio).toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 2,
                        })}
                      </small>
                    </td>
                    <td>
                      <small
                        style={{
                          background:
                            colorMap[Number(a.id_estado_pago)] || "green",
                          color: "white",
                          borderRadius: "3px",
                          padding: "5px",
                        }}
                      >
                        <span>{a.estado_pago}</span>
                      </small>
                    </td>
                    <td>
                      <h4>
                        {datos.filter((x) => Number(x.id) === Number(a.id))
                          .length > 0 ? (
                          <BsIcons.BsRecord2Fill
                            onClick={() => handleClickNotd(a)}
                          />
                        ) : (
                          <BsIcons.BsRecord
                            onClick={() => handleClickSelect(a)}
                          />
                        )}
                      </h4>
                    </td>
                  </tr>
                ))}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4" className="text-end">
                <strong>Total</strong>
              </td>
              <td className="text-end">{turnos && totalCobrado(turnos)}</td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  );
};

export default Report;
