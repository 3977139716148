import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import generico from "./utils/user-generico.png";
import Spinner from "react-bootstrap/Spinner";
import CustomerUpdate from "../CustomerUpdate/CustomerUpdate";
import useAuth from "../../../auth/useAuth";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CryptoJS from "crypto-js";

const CustomerList = () => {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const [ok, setOk] = useState(false);
  const [item, setItem] = useState([]);
  const [itemSe, setItemSe] = useState([]);
  const [view, setView] = useState(true);
  const [filtro, setFiltro] = useState("");
console.log(item)
  const getData = () => {
    axios
      .get(apiConfig + "customer/list/index.php?id=" + user.id_business_unit)
      .then((response) => {
        setItem([...response.data]);
      });
  };

  useEffect(() => {
    axios
      .get(apiConfig + "customer/list/index.php?id=" + user.id_business_unit)
      .then((response) => {
        setItem(response.data);
        setOk(true);
      });
  }, [user.id_business_unit]);



  function onActive(id) {
    axios.get(apiConfig + `customer/delete/active.php?id=${id}`).then(() => {
      getData();
    });
  }

  const onDelete = (id) => {
    axios.get(apiConfig + `customer/delete/delete.php?id=${id}`).then(() => {
      getData();
    });
  };

  function itemSeleccionado(a) {
    setView(false);
    setItemSe(a);
  }

  const views = () => {
    setView(true);
  };

  return (
    <>
      {view ? (
        <div className="container">
          <div className="text-start">
            <NavLink exact to={"/config"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
          <div className="card shadow mb-3" style={{ background: "#736CED" }}>
            <div className="card-body">
              <div className="text-center text-white">
                Clientes
              </div>
            </div>
          </div>
          {ok ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "40px",
                  padding: "5px",
                }}
              >
                <div className="text-end text-muted ">
                  <NavLink exact to={"/config/customerImport/"}>
                    <h1>
                      <BsIcons.BsFileEarmarkArrowUp
                        style={{ color: "#736CED" }}
                      />
                    </h1>
                  </NavLink>
                </div>

                <div className="text-end text-muted ">
                  <NavLink exact to={"/config/customerAdd/"}>
                    <h1>
                      <BsIcons.BsPlusCircleFill style={{ color: "#736CED" }} />
                    </h1>
                  </NavLink>
                </div>
              </div>
              <div className="mb-5">
                <div className="input-group pt-2">
                  <input
                    placeholder="Buscar"
                    className="form-control form-control-sm"
                    type="text"
                    onChange={(e) => setFiltro(e.target.value)}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <BsIcons.BsSearch />
                  </span>
                </div>
              </div>
			  <Row>
				
				{item
                .filter(
                  (datos) =>
                    datos.nombre
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.apellido
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
					datos.seudonimo
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase()) ||
                    datos.telefono
                      .toLocaleLowerCase()
                      .includes(filtro.toLocaleLowerCase())
                )
                .map((data, index) => {
                  return (
					<Col md={6} sm={12} key={index}>
                    <div key={data.id}>
					<NavLink                          
                          exact
                          to={
                            "/customer/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(data),
                                "clave_secreta"
                              ).toString()
                            )
                          }
                        >
                      <div
                        className="card shadow mb-3 active"
                        
                        
                      >
                        <div className="card-body">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div className="profile-pic">
                                <img
                                  src={
                                    data.avatar_url
                                      ? apiConfig +
                                        "customer/image/" +
                                        data.avatar_url
                                      : generico
                                  }
                                  alt="cronogram"
                                />
                              </div>
                            </div>
                            <div style={{ flex: 7,color:'black' }} className="text-start">
                              <strong>{data.nombre},{data.apellido}</strong>
                              <br />
                              <span className="text-muted">
                                {data.telefono}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
					  </NavLink>
                    </div>
					</Col>
                  );
                })}
				  </Row>
              
            </>
          ) : (
            <div className="text-center" style={{ paddingTop: "20%" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      ) : (
        <CustomerUpdate item={itemSe} views={views} getData={getData} onDelete={onDelete}/>
      )}
    </>
  );
};

export default CustomerList;
