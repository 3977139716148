const apiConfig = 'https://bioma.cronogram.com.ar/back/';
const apiService = 'https://bioma.cronogram.com.ar/back/';
// const apiConfig = 'http://localhost/back/';
// const apiService = 'http://localhost/back/';
const apiWs = 'https://api.cronogram.com.ar/';
const tokenWs = "WqwobwscwY1qRuV2mvNohoHtTecBqUUSSdmwwun2ba01ac05";
const instances = "17533";

export { apiConfig, tokenWs, instances, apiService, apiWs };


  
